<template>
  <div class="thank-you">
    <div class="modal__body thank-you__body">
      <h2 class="thanks__main-title">
        <span class="icon icon__check icon__check_dark thank-you__title-icon" />
        <span>All samples submitted.</span>
        <span>Thank you!</span>
      </h2>
      <div v-if="session_quality" class="thank-you__warning-wrapper">
        <div class="thank-you__warning">
          <h5 class="thank-you__warning-title">
            <span class="thank-you__warning-icon"></span>
            {{ session_quality.title }}
            <span v-if="session_quality.cause">
              : {{ session_quality.cause }}
            </span>
          </h5>
          <p class="thank-you__warning-text">
            {{ session_quality.message }}
            <router-link :to="tryAgainRoute">Please try again</router-link>
          </p>
          <div class="thank-you__left-border"></div>
        </div>
      </div>
    </div>
    <div class="thank-you__footer">
      <button class="button button_secondary button_md" @click="$emit('close')">
        <span>Continue</span>
      </button>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { ROUTE_AUDIO_CALIBRATION } from "@/constants";

export default {
  computed: {
    ...mapState(["session_quality"]),
    tryAgainRoute() {
      return { name: ROUTE_AUDIO_CALIBRATION };
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/basic/variables";

.thank-you {
  &__body {
    text-align: center;
    padding: 32px 0;
  }
  &__title-icon {
    margin-right: 8px;
  }
  &__warning-wrapper {
    padding: 0 24px;
  }
  &__warning {
    padding: 12px 16px;
    border: 1px solid #ef4e4e;
    border-radius: 3px;
    position: relative;
  }

  &__warning-title {
    font-weight: 900;
    font-size: 14px;
    line-height: 26px;
    color: #ef4e4e;
  }

  &__warning-text {
    font-size: 16px;
    line-height: 26px;
    color: darken(#ef4e4e, 40%);

    & a {
      text-decoration: none;
      color: $color-secondary-dark;
      font-weight: $weight-bold;
    }
  }
  &__left-border {
    position: absolute;
    top: 0;
    left: 0;
    width: 4px;
    height: 100%;
    background-color: #ef4e4e;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }

  &__warning-icon {
    display: inline-block;
    width: 16px;
    height: 14px;
    background-image: url("~@/assets/images/warning.svg");
    background-repeat: no-repeat;
    background-size: 16px 14px;
    margin-right: 4px;
  }
}
</style>
